import React from "react"
import Layout from "../components/Layout"
import ProductColumnGenerator from "../components/ProductColumnGenerator"
import { DEFAULT_IMG_URL, BASE_BREADCRUMBS } from "../constants"

const SearchResults = ({ location }) => {

  return (
    <Layout
      breadcrumbs={BASE_BREADCRUMBS}
      fullWidth
      pageTitle={`Search Results: ${location.state?.searchQuery}`}
    > {
        location.state?.hits?.length > 0 ?
          <ProductColumnGenerator
            array={location.state.hits.map(node => ({
              img: node.image || DEFAULT_IMG_URL,
              url: node.url,
              name: node.name,
            }))}
          />
          :
          <span className="m-3">There are no results.</span>
      }
    </Layout>
  )
}

export default SearchResults



